import { decodeObjectGUID, decodeJWT } from 'utils/helpers/decode';
import {
    ApiClient,
    KeycloakAccessTokenDecodeType,
    KeycloakTokenResponseType,
    Session
} from './types';
import { CLIENT_KEY_LOCAL_STORAGE } from './localStorage';
import { useState } from 'react';
import { createMinioStorage } from 'modules/storage/minioStorage';

// Функция для создания клиента с поддержкой Keycloak
const storageApiUrl = window.env.STORAGE_API_URL;
export const createKeycloakClient = (): ApiClient => {
    // let session: Session = window.localStorage.getItem('session');
    // // let acessToken = null;

    let refreshingPromise: Promise<void> | null = null; // промис чтобы был только один запрос на обнавление токена и остальные запросы ждали его обнавления

    // if (session) {
    //     session = JSON.parse(session);
    // acessToken = session?.data?.access_token;
    // }

    const client: ApiClient = {
        authProvider: 'keycloak',
        session: null,
        // accessToken: acessToken,
        async getUser() {
            return {
                data: this.session?.data?.user
            };
        },

        async getSession() {
            if (!this.session) {
                throw new Error('No session found');
            }

            if (isTokenExpired(this.session?.data?.access_token)) {
                // Обновляем токен, если он истёк
                await this.refreshAccessToken();
            }

            return this.session;
        },

        async setSession(accessToken: string, refreshToken: string) {
            this.session = { data: { access_token: accessToken, refresh_token: refreshToken } };
            return this.session;
        },

        async signOut() {
            console.log('Keycloak signOut');
            this.session = null;
            window.localStorage.removeItem(CLIENT_KEY_LOCAL_STORAGE);
        },
        async isAuthenticated() {
            // const sessionExpired =
            //     this.session && this.session.data && isTokenExpired(this.session.data.access_token);
            return this.session !== null;
        },
        async refreshAccessToken() {
            if (refreshingPromise) {
                // Если обновление уже запущено, возвращаем текущее Promise
                return refreshingPromise;
            }

            if (!this.session?.data?.refresh_token) {
                console.warn('Сессия истекла, не найден refresh токен');
                this.session = null;
                window.localStorage.clear();
                window.sessionStorage.clear();
                window.location.reload();
            }

            refreshingPromise = (async () => {
                try {
                    const KEYCLOAK_URL = window.env.KEYCLOAK_URL;
                    const response = await fetch(KEYCLOAK_URL, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        body: new URLSearchParams({
                            grant_type: 'refresh_token',
                            client_id: 'itvectura-frontend',
                            refresh_token: this?.session?.data?.refresh_token || ''
                        })
                    });

                    if (!response.ok) {
                        console.warn('Сессия истекла, требуется повторная авторизация');
                        this.session = null;

                        window.localStorage.clear();
                        window.sessionStorage.clear();
                        window.location.reload();
                    }

                    const data = await response.json();
                    this.session = {
                        data: {
                            access_token: data.access_token,
                            refresh_token: data.refresh_token,
                            user: this.session?.data?.user
                        }
                    };

                    // Сохраняем обновлённую сессию
                    window.localStorage.setItem(CLIENT_KEY_LOCAL_STORAGE, JSON.stringify(this));

                    console.debug('Сессия истекла, успешно обновили по токену');
                } catch (error) {
                    console.error('Error refreshing access token:', error);
                    throw error;
                } finally {
                    refreshingPromise = null; // Завершаем процесс обновления
                }
            })();

            return refreshingPromise;
        },

        async signIn(email: string, password: string) {
            // console.debug('authKeycloak');
            const KEYCLOAK_URL = window.env.KEYCLOAK_URL;

            if (!KEYCLOAK_URL) {
                throw new Error('Keycloak URL not found');
            }

            const response = await fetch(KEYCLOAK_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({
                    grant_type: 'password',
                    client_id: 'itvectura-frontend',
                    username: email,
                    password: password
                })
            });

            if (!response.ok) {
                const errorText = await response.text();
                // throw new Error(`Failed to authenticate with itvectura: ${errorText}`);
                return {
                    error: `Failed to authenticate with Keycloak: ${errorText}`
                };

                // throw new Error('Failed to authenticate with Keycloak');
            }

            const data: KeycloakTokenResponseType = await response.json();
            const decodedToken: KeycloakAccessTokenDecodeType = decodeJWT(data.access_token);
            const decodeObjectGuid = decodeObjectGUID(decodedToken.objectGUID);

            // Сохраняем данные авторизации Keycloak
            console.log('Аутентифицирован через keycloak');
            const user = {
                id: decodeObjectGuid,
                name: decodedToken.name,
                email: email
            };
            this.session = {
                data: {
                    access_token: data.access_token,
                    refresh_token: data.refresh_token,
                    user
                }
            };
            // this.accessToken = data.access_token;
            // window.localStorage.setItem('session', JSON.stringify(this.session));
            return {
                data: {
                    user,
                    session: {
                        access_token: data.access_token,
                        refresh_token: data.refresh_token,
                        user
                    }
                },
                error: undefined
            };
        }

        // storage: createMinioStorage(storageApiUrl, () => {
        //     debugger;
        //     return this.session?.data?.access_token;
        // })
    };
    client.storage = createMinioStorage(storageApiUrl, () => {
        return client.session?.data?.access_token || '';
    });
    return client;
};

export function isTokenExpired(token: string | undefined): boolean {
    if (!token) return false;
    const decodedToken = decodeJWT(token);
    const currentTime = Math.floor(Date.now() / 1000);
    const result = decodedToken.exp < currentTime;
    return result;
}
