import { Flex, Typography } from 'antd';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsync, useAsyncFn, useList } from 'react-use';
import { ListActions } from 'react-use/lib/useList';

import {
    RunStatusCreated,
    RunStatusEnum,
    RunStatusFailed,
    RunStatusFailedToEnqueue,
    RunStatusQueued,
    RunStatusSkipped,
    RunStatusStarted,
    RunStatusSucceeded
} from 'modules/services/backend-api/generated_info';
import { RunRequest, Response, LogsResponse } from 'modules/services/backend-api/generated_api';
import { LogMessage } from 'modules/services/backend-api/generated_smart_context';
import { LogsField } from 'smart/components/LogsField/LogsField';
import { StoreLink } from 'ui';
import { useNotifications } from 'utils/hooks';
import { metaStore } from 'utils/store/MetaStore';
import { v4 } from 'uuid';

const NotifyDescription = ({
    requestId,
    logMessages,
    linkText,
    text,
    navParams,
    onCloseNotify
}: {
    navParams?: { to: string; state: { filterString: string } };
    text?: string;
    requestId?: string;
    logMessages?: LogsResponse;
    linkText?: string;
    onCloseNotify?: () => void;
}) => (
    <>
        <Typography.Paragraph style={{ padding: 0, marginBottom: 2 }}>{text}</Typography.Paragraph>
        <Flex gap={5} align="center">
            {navParams && linkText ? (
                <StoreLink style={{ padding: 0 }} {...navParams} onClick={onCloseNotify}>
                    {linkText}
                </StoreLink>
            ) : null}
            {requestId && (
                <div style={{ position: 'absolute', bottom: 10, right: 20 }}>
                    <LogsField
                        requestId={requestId}
                        logMessages={logMessages}
                        onClick={onCloseNotify}
                    />
                </div>
            )}
        </Flex>
    </>
);

export const useHandlerRun = (customLogsMethods?: Partial<ListActions<LogMessage>>) => {
    const { t } = useTranslation();
    const { notification } = useNotifications();

    const [logs, logsMethods] = useList<LogMessage>([]);

    // const [showLogButton, setShowLogButton] = useState<boolean>(false);

    const { value: showLogButton } = useAsync(async () => {
        const showLogButtonParam = metaStore.meta.get('all')?.params?.SHOW_LOG_BUTTON;

        if (showLogButtonParam) return showLogButtonParam;

        return metaStore.getParam({
            param_name: 'SHOW_LOG_BUTTON',
            default_value: true
        });
    }, []);

    // useEffectOnce(() => {
    //     const fethcParam = async () => {
    //         const showLogButtonParam = metaStore.meta.get('all')?.params?.SHOW_LOG_BUTTON;

    //         if (showLogButtonParam) {
    //             setShowLogButton(showLogButtonParam as unknown as boolean);
    //         } else {
    //             const showLogButtonParam = await metaStore.getParam({
    //                 param_name: 'SHOW_LOG_BUTTON',
    //                 default_value: true
    //             });
    //             setShowLogButton(showLogButtonParam as boolean);
    //         }
    //     };

    //     fethcParam();
    // });

    const requestsRoute = useMemo(() => {
        return metaStore.meta.get('all')?.routesMap?.get('InfoRequests')?.[0];
    }, []);

    const uiAllowView = useMemo(() => {
        return metaStore.meta.get('InfoRequests')?.info?.UiAllowView ?? true;
    }, []);

    const handleClickNotifyActions = (key: string) => {
        notification.destroy(key);
    };

    const getRequestNavParams = useCallback(
        (requestId: string | undefined) => {
            if (requestsRoute) {
                const [pathname, filterString] = requestsRoute.path.split('?');

                if (!requestId) return undefined;

                return {
                    to: pathname.endsWith('/')
                        ? `${pathname}${requestId}`
                        : `${pathname}/${requestId}`,
                    state: {
                        filterString
                    }
                };
            }

            return undefined;
        },
        [requestsRoute]
    );

    // Generalized function to handle different statuses
    const handleNotification = ({
        status,
        requestId,
        response,
        errorText,
        notificationKey
    }: {
        status: RunStatusEnum;
        requestId: string | undefined;
        response: Response | undefined;
        errorText?: string;
        notificationKey?: string;
    }) => {
        const key = notificationKey ?? v4();

        const notificationConfig = {
            key,
            description: (
                <NotifyDescription
                    navParams={getRequestNavParams(requestId)}
                    requestId={showLogButton ? requestId : undefined}
                    logMessages={response?.log_messages}
                    linkText={uiAllowView ? `${t('show_details')}...` : undefined}
                    text={errorText}
                    onCloseNotify={() => handleClickNotifyActions(key)}
                />
            ),
            onClick: () => handleClickNotifyActions(key),
            duration: 20
        };

        switch (status) {
            case RunStatusSucceeded:
            case RunStatusQueued:
                notification.success({
                    ...notificationConfig,
                    message: t('handler_response_success')
                });
                break;
            case RunStatusFailed:
            case RunStatusSkipped:
            case RunStatusFailedToEnqueue:
                notification.error({
                    ...notificationConfig,
                    message: t('handler_response_fail'),
                    duration: 60
                });
                break;
            case RunStatusCreated:
            case RunStatusStarted:
                notification.info({
                    ...notificationConfig,
                    message: t('handler_response_info')
                });
                break;
            default:
                notification.info({
                    ...notificationConfig,
                    message: t('handler_response_unknown')
                });
        }
    };

    const [result, run] = useAsyncFn(
        async (options: RunRequest, disableNotify?: boolean, notifyOnlyWhenError?: boolean) => {
            const response = await metaStore.makeRun(options);

            if (notifyOnlyWhenError) {
                if (!response?.error_text) {
                    return response;
                }
            }

            if (disableNotify) return response;

            const requestId = response?.request_id;
            const status = response?.run?.[0]?.Status as RunStatusEnum;
            const errorText = response?.error_text;
            const notificationKey = options.Action_Id ?? options.handler;

            handleNotification({
                status,
                requestId,
                response,
                errorText,
                notificationKey
            });

            const logMessages = response?.log_messages;

            if (logMessages && logMessages.length) {
                if (customLogsMethods?.set) customLogsMethods.set(logMessages);
                else logsMethods.set(logMessages);
            }

            return response;
        },
        [customLogsMethods?.set, getRequestNavParams, t, showLogButton]
    );

    return { result, run, logs: { value: logs, ...logsMethods } };
};
