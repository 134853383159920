// Code generated by tygo. DO NOT EDIT.
/* eslint-disable */
import { CODE, ANY_DATA, OBJECT_ID, MULTILANG_TEXT, USERID, VALUE_TYPE, ANY_TYPE, COLOR } from './generated_types';

//////////
// source: alias.go

export interface Alias {
}

//////////
// source: const.go

export const DictionaryTypeCode: CODE = "DCT";
export const DocumentTypeCode: CODE = "DOC";
export const CatalogTypeCode: CODE = "CAT";
export const SaveHandlerCode: CODE = "Save";

//////////
// source: dynamic_fields.go

export const InfoObjectVirtualFieldsHashmapName = "VirtualFields";
/**
 * VirtualFields тип для динамических (виртуальных) полей во всех моделях
 */
export type VirtualFields = { [key: string]: any};

//////////
// source: info_actions.go

/**
 * ActionTypes represents types of actions for tables: HANDLER, EVENT, ASSOCIATION.
 */
export interface ActionType extends Dictionary {
    Name: MULTILANG_TEXT;
}
/**
 * Actions represents actions for UI tables.
 */
export interface Action extends Object {
    Meta_Code: CODE; // NB! это НЕ FK
    Parent_Id?: OBJECT_ID; // родительский экшн - папка
    ChildIndex?: number /* int */;
    Type_Code: CODE;
    Name: MULTILANG_TEXT;
    IsFolder: boolean; // TODO: нужно ли это и нужно ли type=folder? не факт!
    Handler_Code?: CODE;
    EventType_Code?: CODE;
    Association_Code?: CODE;
    IsVisibleInList: boolean;
    IsVisibleInForm: boolean;
    IsVisibleInContextMenu: boolean;
    IsVisibleInFrame: boolean;
    IsBuiltIn: boolean;
    IsNavigationFirst: boolean;
    IsLogResultHidden: boolean;
    IsDisabled: boolean;
    IsActive: boolean;
    IsSingle: boolean; // умеет обрабатывать только один объект
    IsButton: boolean;
    Icon?: string;
    /**
     * Если у нас ассоциация, то должны быть заполнены одно из двух полей
     */
    NavItem_Id?: OBJECT_ID; // переход на известную страницу
    NavigationPath?: string; // нестандартный переход на произвольный URL
    Type: ActionType;
    Parent?: Action; // кажется не нужно! UPDATE: нужно для select
    /**
     * Chidren     []Action     `gorm:"foreignKey:ParentId"`                        // TODO: кажется не нужно!
     */
    Handler?: Handler; // нужно чтобы вызывать обработчик
    EventType?: EventType; // нужно чтобы вызывать событие
    Association?: Association; // нужно чтобы вызывать ассоциацию
    NavItem?: NavItem; //  нужно для фронтенда чтобы знать куда делать переход на ассоциацию
    SavedQuery?: SavedQuery;
    Conditions: ActionCondition[]; // условия применения действия
    /**
     * массив из экранов (пунктов меню), на которых нужно показывать экшн
     */
    AllowedForNavItems?: OBJECT_ID[];
}
export interface ActionCondition extends Object {
    Action_Id: OBJECT_ID; // на какой экшн задаем условие
    /**
     * Условия применения Action - проверяются и на фронтенде (чтобы дизайблить плохие экшны) и на бэкенде!
     */
    Meta_Code?: CODE; // nullable
    Type_Code?: CODE; // nullable
    Status_Code?: CODE; // nullable
    /**
     * TODO: а как тут будут выбирать ТИП? да и мету - если мета не вся есть в БД?
     */
    Status?: Status;
}

//////////
// source: info_documents.go

export const InfoObjectDocumentsMeta_Code: CODE = "InfoObjectDocuments";
export const InfoObjectDocumentsArrayName = "Documents";
export interface ObjectDocument extends Object {
    Meta_Code: CODE; // Used as ObjectType, это НЕ FK
    Object_Id: OBJECT_ID; // ID of the parent model. Может быть и int64 и UUID!
    ChildIndex?: number /* int */; // порядковый номер внутри объекта
    Document_Id: OBJECT_ID; // ID документа (FK на doc_documents(id))
}

//////////
// source: info_event_subscriptions.go

export const REQUEST_TYPE_SAVE: CODE = "SAVE";
export const REQUEST_TYPE_ACTION: CODE = "ACTION";
/**
 * EventTypes represents types of events raised by handlers.
 */
export interface EventType extends Dictionary {
    Meta_Code: CODE; // NB! это НЕ FK
    Name: MULTILANG_TEXT;
    IsAfterCreating: boolean;
    IsAfterSettingStatus: boolean;
    /**
     * Meta          Meta           `gorm:"foreignKey:Meta_Code;references:Code"`
     */
    Subscriptions: Subscription[];
}
/**
 * Subscription represents event type subscriptions.
 */
export interface Subscription extends Object {
    Code?: CODE;
    EventType_Code: CODE;
    Name: MULTILANG_TEXT;
    Description: MULTILANG_TEXT;
    IsSisabled: boolean;
    IsAsyncHandlers: boolean;
    /**
     * это - подписка к мете и ее типу
     */
    Meta_Code?: CODE;
    ObjectType_Code?: CODE;
    ConditionalStatus_Code?: CODE;
    ConditionalStatuses?: ANY_TYPE;
    SavedQuery?: SavedQuery;
    EventType: EventType;
    ChildSubscriptionHandlers: SubscriptionHandler[];
    ConditionalStatus?: Status;
}
/**
 * SubscriptionHandlers represents handlers in event type subscriptions.
 */
export interface SubscriptionHandler extends Object {
    ParentSubscription_Id: OBJECT_ID;
    ChildIndex?: number /* int */;
    Association_Code?: CODE; // если указана ассоциация то обработчик будет вызываться для объектов являющихся РЕЗУЛЬТАТОМ ассоциации
    Handler_Code: CODE;
    IsDisabled: boolean;
    Association?: Association;
}

//////////
// source: info_extrafields.go

export const ExtraFieldsFieldName = "ExtraFields";

//////////
// source: info_handler_event_runs.go

export type RunStatusEnum = string;
export const RunStatusCreated: RunStatusEnum = "CREATED";
export const RunStatusQueued: RunStatusEnum = "QUEUED"; // не будет использвоаться - вместо этого будет QueuedAt
export const RunStatusFailedToEnqueue: RunStatusEnum = "ENQUEUE_FAILED";
export const RunStatusStarted: RunStatusEnum = "EXECUTION_STARTED";
export const RunStatusCanceled: RunStatusEnum = "EXECUTION_CANCELED";
export const RunStatusFailed: RunStatusEnum = "EXECUTION_FAILED";
export const RunStatusSucceeded: RunStatusEnum = "EXECUTION_SUCCEEDED";
export const RunStatusSkipped: RunStatusEnum = "EXECUTION_SKIPPED"; // из-за предыдущей ошибки - только для синхронных запусков!
export const RunStatusLoopDetected: RunStatusEnum = "EXECUTION_LOOP_DETECTED"; // Принудительная отмена из-за зацикливания
export type IRun = any;
/**
 * HandlerRuns represents instances of running handlers (started from Action or from Subscription)
 */
export interface HandlerRun extends Object {
    Handler_Code: CODE; // done: add this field
    Object_Ids: string[]; // array of strings! done: add this field
    Args?: ANY_DATA;
    Session_Id?: string; // done: add field
    Request_Id?: string; // done: add field
    LoggerFields?: ANY_DATA; // done: add field
    UserClaims?: string; // done: add field
    QueuedAt?: Date; // только для асинхронных
    StartedAt?: Date; // для асинхронных заполняется уже когда запускается
    FinishedAt?: Date;
    Result?: ANY_DATA;
    TemporalTaskId?: string;
    Status: RunStatusEnum; // done: добавить это поле и заполнять его
}
export type HandlerRunArray = (HandlerRun | undefined)[];
/**
 * EventRuns represents instances of running events.
 */
export interface EventRun extends Object {
    EventType_code: CODE; // тут однозначно известен Meta
    Object_Id: string; // done: update field to string
    Args?: ANY_DATA;
    Session_Id?: string; // done: add
    Request_Id?: string; // done: add updated: nullable поле! и оно в тестах не заполняется т.к. тут FK на info_requests
    LoggerFields?: ANY_DATA; // done: add
    /**
     * TODO: add non-logger fields - data fields
     */
    UserClaims?: string; // done: add
    TemporalTaskId?: string;
    QueuedAt?: Date; // только для асинхронных
    StartedAt?: Date; // для асинхронных заполняется уже когда запускается
    FinishedAt?: Date;
    Result?: ANY_DATA; // поле есть - TODO: что тут будет? кажется ничего
    Status: RunStatusEnum; // done: добавить это поле и заполнять его
    EventType: EventType;
}

//////////
// source: info_handler_schedules.go

/**
 * Handlers represents handler definitions.
 */
export interface HandlerSchedules extends Object {
    Code?: CODE; // опциональный код
    Handler_Code?: CODE; // done: implement it
    StartAt?: Date;
    EndAt?: Date;
    Pattern: string;
    IsActive: boolean;
    Handler?: Handler;
}

//////////
// source: info_handlers.go

export type HandlerTypeCode = CODE;
export const HANDLER_TYPE_EMPTY: HandlerTypeCode = "EMPTY"; // nothing (no code inside handler. can be used to raise events or change statuses upon completion of such handler)
export const HANDLER_TYPE_CODE: HandlerTypeCode = "CODE"; // golang code
export const HANDLER_TYPE_SCRIPT: HandlerTypeCode = "SCRIPT"; // java script
export const HANDLER_TYPE_CAMUNDA: HandlerTypeCode = "CAMUNDA"; // obsolete
export const HANDLER_TYPE_SQL: HandlerTypeCode = "SQL"; // SQL script
/**
 * HandlerTypes represents categories of handlers like EMPTY, CODE, SCRIPT, CAMUNDA, etc.
 */
export interface HandlerType extends Dictionary {
    Name: MULTILANG_TEXT;
    Handlers: Handler[];
}
/**
 * Handlers represents handler definitions.
 */
export interface Handler extends Dictionary {
    Meta_Code: CODE; // NB! это НЕ FK, NULLABLE
    Type_Code: HandlerTypeCode;
    Name: MULTILANG_TEXT;
    Description: MULTILANG_TEXT;
    MethodName?: string; // название метода в коде GO
    ScriptText?: string;
    IsDisabled: boolean;
    IsAsyncEvents: boolean;
    IsForArray: boolean; // умеет обрабатывать массив id объектов (иначе по одному объекту)
    IsForNoneObjects: boolean; // если true для вызова не требуем Ids
    SuccessStatus_Code?: CODE; // done: implement it
    SuccessEventType_Code?: CODE; // done: implement it
    DontSaveResultInHandlerRun: boolean; // если true не сохраняем результат в info_handler_run, для супер хэндлеров которые возвращают ПРОСТО ТОННУ ВСЕГО может пригодится
    IsRestApiEnabled: boolean; // Indicates if this handler is exposed via REST API
    RestApiMethod?: string; // HTTP method ("GET", "POST", etc.)
    RestApiPath?: string; // URL path (e.g., "/api/my-handler")
    /**
     * Meta        Meta           `gorm:"foreignKey:Meta_Code;references:Code"`
     */
    Type?: HandlerType;
    ChildParams: HandlerParam[];
    SuccessStatus?: Status;
    SuccessEventType?: EventType;
}
/**
 * HandlerParams represents handler parameters.
 */
export interface HandlerParam extends Object {
    ParentHandler_Code: CODE;
    ChildIndex?: number /* int */;
    ParamName: string; // тут camelCase имя параметра который потом из Params можно доставать
    Name: MULTILANG_TEXT;
    ValueType: VALUE_TYPE; // can be "string", "bool", "int", ..., "ref:meta.field", "ref:meta.field,filter:field=value"
    IsRequested: boolean;
    IsMandatory: boolean;
    DefaultValue?: string; // nullable // done: add to db
    ParentHandler?: Handler;
}

//////////
// source: info_meta.go

/**
 * Base table types
 * TODO: у нас проблема курицы и яйца. Мы не можм отсюда ссылаться на SettingsUsers
 */
export interface Object {
    Id?: OBJECT_ID;
    CreatedAt?: Date; // Auto set create time
    CreatedBy?: USERID;
    LastUpdatedAt?: Date; // Auto set update time
    LastUpdatedBy?: USERID;
    Ver?: number /* int64 */; // Version for optimistic locking, if used
}
/**
 * Dictionary represents a generic dictionary table structure with a unique, non-nullable code.
 */
export interface Dictionary extends Object {
    Code: CODE; // Ensures Code is unique and not null in the database
}
export interface Document extends Object {
    Key: any /* types.KEY */; // Ensures Key is unique and not null in the database
}
export interface Register extends Object {
}
/**
 * MetaTypes represents meta object types
 * INFO (информационные/мета данные)
 * SYS (системные данные)
 * MISC (разное)
 * DCT (словари и enum-ы)
 * CAT (каталоги aka мастер-данные)
 * DOC (транзакционные данные)
 * REG (регистры)
 * AGG (агрегаты)
 * LOG (лог-данные)
 */
export interface MetaType extends Dictionary {
    Name: MULTILANG_TEXT;
    /**
     * TODO: сделать опциональным - это же nullable
     */
    ViewFieldName?: string; // поле которое однозначно идентифицирует объект - выводится в таблице и в select field (DOC - key, CAT - short_title, DIC - key )
}
/**
 * Meta represents the info_meta table
 * TODO: для меты заменить поле code на meta
 */
export interface Meta extends Object {
    /**
     * done: add in DB prefixes "meta_" to all tables here (insdead of dict/sys)! или ЛУЧШЕ префикс info_ ??!!! info_meta, info_status_groups - да - так лучше!
     * TODO: rename feld in DB to "meta" and rename all refernece fields to "meta" too
     * UPDATE:нет- в БД правильно называть это code! это тут  нужно переименовать в code и все ссылаются как на meta_code. будет пара meta_code+object_id когда создан и meta_code+type_code при создании объекта
     */
    Code: CODE; // Ensures Code is unique and not null in the database
    TableName?: any /* types.TABLE_NAME */; // nullable! // TODO make a pointer field
    SingularName: MULTILANG_TEXT; // done: rename "single_name" to "singular_name"
    PluralName: MULTILANG_TEXT;
    Description: MULTILANG_TEXT;
    RootMeta_Code?: CODE; // TODO: потом когда-нибудь когда приспичит. если мы - дочерний узел то нужно еще поле задающее название нас как массива в корне. По умолчанию функция GetArrayName это инферрит
    SourceHistoryMeta_Code?: CODE;
    IsDisabled: boolean;
    IsHidden: boolean; // чтобы в меню скрывать но роут был
    IsReadOnly: boolean;
    IsFilterFirst: boolean;
    ArrayNameInRoot?: string; // done: добавить это поле в БД. для дочерних узлов - тут название массива в корневом узле. done: нужно генерировать это поле по логике как в GetChildNodesArrayName() и метод убрать - использовать это поле
    ShouldUseRls: boolean;
    CanHaveDocuments: boolean;
    CanHaveParameters: boolean;
    CanHaveScopes: boolean;
    UiAllowSelect: boolean;
    UiAllowView: boolean;
    UiAllowEdit: boolean;
    UiAllowCreate: boolean;
    UiAllowSave: boolean;
    UiAllowDelete: boolean;
    ApiAllowInfo: boolean;
    ApiAllowSelect: boolean;
    ApiAllowGet: boolean; // +read attachments
    ApiAllowSave: boolean; // +write/delete attachments
    ApiAllowPreload: boolean;
    ShouldUseRolePolicies: boolean; // если здесь TRUE то по умолчанию все поля Ui/Api Allow  == false и разрешения даются в ролях! NB! при загрузке Meta мы загружаем роли и корректируем Ui/Api Allow поля
    Filters?: ANY_DATA;
    /**
     * layout_area и child_index только для детского узла. Для корневого узла тут пусто (для него мы поля его настраиваем)
     */
    LayoutArea?: string;
    ChildIndex?: number /* int */;
    SortOrder?: string; // "child_index asc, name->>ru desc"
    KeyGenerator_Code?: CODE; // done: add this field
    /**
     * TODO: нужно убрать это поле!
     */
    RefViewTemplate?: string;
    ViewHandler_Code?: CODE;
    /**
     * Refs and Children:
     */
    Type: MetaType;
    /**
     * aka DisplayNameField
     */
    ViewFieldName?: string; // поле которое однозначно идентифицирует объект - выводится в таблице и в select field (DOC - key, CAT - short_title, DIC - key )
    RefFields?: string; // дополнительыне поля для отображения в реф-объекте
    DisplayNameFields?: string; // поля для отображения в реф-объекте в DisplayName чтобы он правильно рассчитался
    Handlers: Handler[]; // это на самом деле НЕ FK
    Actions: Action[]; // это на самом деле НЕ FK
    Fields: MetaField[];
    StatusGroups: StatusGroup[];
    /**
     * нельзя называть Properties. т.к. это зарезервированное название поля для сгенерированных структур - и оно загружается с помощью хука иначе
     */
    Properties: Property[];
    Parameters: Parameter[];
    /**
     * "детские (зависимые) узлы" и "корень" - тут нет вложенной иерархии - всегда один корень и у него зависимые узлы (у каждого есть root_id для связи с корнем)
     */
    ChildNodes: Meta[];
    RolePolicies: RolePolicy[];
    /**
     * нельзя называть Partners. т.к. это зарезервированное название поля для сгенерированных структур - и оно загружается с помощью хука иначе
     */
    Partners: Partner[]; // тут все роли партнеров которые должны быть определены для меты
    KeyGenerator?: KeyGenerator;
}
export const ChildIndexFieldName = "ChildIndex";
export const ROOT_FIELD_NAME = "Root";
export const ROOT_DBCOLUMN_NAME = "root_id";
export const DONT_GENERATE_META = false; // теперь мету генерируем тоже!
/**
 * тут все поля, но могут быть и не поля а другие свойства возможно
 */
export interface MetaField extends Object {
    Meta_Code?: CODE; // теперь это поле nullable! могут быть поля без меты - переиспользуемые для всех
    FieldName: string; // имя поля в структуре объекта (использутся когда нужно на него сослаться в ref)
    Name: MULTILANG_TEXT;
    Description: MULTILANG_TEXT;
    ColumnName: any /* types.COLUMN_NAME */; // in the table
    ValueType?: VALUE_TYPE; // can be "string", "bool", "int", ..., "ref:meta.field", "ref:meta.field;filter:field=value", "_bool"=array of bool, "*bool" - optional (nullable) bool. Пример: "*int64;ref:User.Id;filters:Active=true"
    DbValueType?: VALUE_TYPE; // тип поля, на основе схемы БД
    IsHiddenOnTable: boolean; // done: add is_hidden (для ver, итп)
    IsHiddenOnDetail: boolean; // done: add is_hidden (для ver, итп)
    Source?: string; // Для вычислимых полей тут на птичьем языке указываем откуда брать данные для этого поля. Пример: "join:DocsTransportationTripStops,on:DocsTransportationTripStops.RootId=DocsTransportationTrips.Id,fields:{Location,StopCategory,ArrivalPlannedA,DeparturePlannedAt}"
    /**
     * => FirstStop: { Location: { Id }, StopCategory: { Code }, ArrivalPlannedAt, DeparturePlannedAt }, ...
     * DocsTransportationTripStops(Location,StopCategory,ArrivalPlannedAt,DeparturePlannedAt);filters:;order=Age.desc,Height.asc;limit=1
     */
    Virtual?: string; // для виртуальных полей - они подтягиваются динамически в VirtualFields хэш-мапу. Могут быть поля - объекты (FK), если они ссылаются на поле-объект (FK).
    IsDisabled: boolean;
    IsReadOnly: boolean;
    IsMandatory: boolean; // для обязательных к заполнению полей. тип, например
    IsLinkHidden: boolean; // для полей которые не должны быть ссылками
    /**
     * layout_area и child_index - ТОЛЬКО для полей корневого узла. У детских узлов тут пусто
     */
    LayoutArea?: string; // Вкладка/Секция например "Main/Header", "Main/Footer", "Main/Audit", "Main/Statuses", "Additional"
    ChildIndex?: number /* int */; // Порядок внутри layout_area
    FilterIndex?: number /* int */; // Порядок внутри фильтров
    Options?: ANY_DATA;
}
export const LastCubeOpt = "last";
export const SumCubeOpt = "sum";
export interface MetaFieldOptions {
}
/**
 * Associations represents associations for navigating between objects.
 */
export interface Association extends Dictionary {
    SourceMeta_Code: CODE; // NB! это НЕ FK
    TargetMeta_Code: CODE;
    Name: MULTILANG_TEXT;
    Description: MULTILANG_TEXT;
}
/**
 * Настройки оповещений (логгирования)
 * DEBUG: Debug - Отладка
 * INFO: Info - Информация
 * WARN: Warning - Предупреждение
 * ERROR: Error - Ошибка
 */
export interface LogLevel extends Dictionary {
    Name: MULTILANG_TEXT;
}
/**
 * Настройки логгирования. По каждой категории можно задавать уровни
 */
export interface LogConfig extends Object {
    Code?: CODE;
    Name: MULTILANG_TEXT;
    Items: LogConfigItem[];
}
export interface LogConfigItem extends Object {
    ParentLogConfig_Id: OBJECT_ID;
    ChildIndex?: number /* int */; // порядок внутри родителя
    /**
     * 1. могут быть уровни без категорий (глобально настройки по уровням)
     * 2. могут быть категории  без уровней (глобально настройки по категориям)
     * 3. могут быть настройки для уровня и категории (настройка на их сочетание)
     * настройки переопределяют друг друга именно в таком порядке ^^^
     */
    Category_Code?: CODE;
    Level_Code?: CODE;
    ShouldSave: boolean; // сохранять ли в базу с логами Loki
    ShouldDisplay: boolean; // показывать ли пользователю
    Category: LogicCategory;
    Level: LogLevel;
    ParentLogConfig?: LogConfig;
}
export interface ObjectLock extends Object {
    /**
     * Polymorphic fields
     */
    Meta_Code: CODE; // Used as ObjectType, это НЕ FK
    Object_Id: OBJECT_ID; // ID of the parent model. Может быть и int64 и UUID!
    LockedAt?: Date;
    LockedBy?: USERID;
    Session_Id: string; // ID сессии юзера - для того чтобы можно было отследить кто заблокировал (юзер из разных вкладок браузера имеет разные сессии)
    WasForced: boolean; // была ли принудительная блокировка
    ExpiresAt?: Date; // время до которого блокировка действительна
}
export interface History {
    Id?: OBJECT_ID;
    TxId: number /* int64 */;
    User_Id: USERID;
    ActionAt: Date;
    Root_TableName: string; // aka meta_code
    Root_Id: string; // aka object_id
    Node_TableName: string; // aka meta_code
    Node_Id: string; // aka object_id
    Operation: string;
}
export interface HistoryField {
    Id?: OBJECT_ID;
    History_Id: OBJECT_ID;
    FieldName: string;
    OldValue: string;
    NewValue: string;
}
export interface EnumType extends Dictionary {
    Name: MULTILANG_TEXT;
    Values: EnumValue[];
}
export interface EnumValue extends Dictionary {
    Type_Code: CODE;
    Name: MULTILANG_TEXT;
    ChildIndex?: number /* int */; // порядок внутри данного типа енума
    Type?: EnumType;
}
/**
 * FIXME: не используется - видимо надо удалить и из БД? Можно енумы вместо этого использовать
 * Common Types для всех объектов
 * info_types (словарь)
 */
export interface Type extends Dictionary {
    Meta_Code: CODE; // NB! это НЕ FK
    Name: MULTILANG_TEXT;
}
/**
 * info_key_generators (не словарь)
 */
export interface KeyGenerator extends Object {
    Code: CODE; // уникальный код генератора ключей
    KeyPrefix: string; // если хотите с "-" например TTR-123 то тут дефис нужно указать! не уникальный - может повторяться с разными last_value для разных диапазонов! и может меняться - поэтому ссылаемся НЕ на него
    LastValue: number /* int64 */; // при создании доумента тут делаем +1 и получаем новый ключ
    MaxValue?: number /* int64 */; // по достижении будем получать ошибку что уже ключ создать нельзя
    NumberLength?: number /* int64 */; // длина числовой части ключа, спереди добивается нулями
}
export interface Layout extends Object {
    Info: ANY_DATA; // переопределение меты
    TablePage: ANY_DATA; // определение лайаута для табличной страницы
    DetailPage: ANY_DATA; // определение лайаута для детальной страницы
    Code?: CODE; // опциональный код
    Meta_Code?: CODE; // опциональная мета
    Key?: any /* types.KEY */; // опциональный ключ
    Name: MULTILANG_TEXT; // опциональное имя
    IsActive: boolean;
}

//////////
// source: info_nav_items.go

export interface NavItem extends Object {
    Path: string; // URL route path
    Component?: string; // React Component (nullable)
    Meta_Code?: CODE; // это мета-объект который передается в компонент // это НЕ FK
    Name: MULTILANG_TEXT; // дефолтное название страницы
    Icon: string; // Material-UI icon name
    IsSingle: boolean; // Открывает DetailPage ОДНОГО объекта. Иначе открывает Table Page с многими объектами и может принимать на вход массив id
    DetailNavItem_Id?: OBJECT_ID; // ссылка на Navitem для DetailPage
    DetailNavItem?: NavItem; // ссылка на Navitem для DetailPage
}

//////////
// source: info_parameters.go

/**
 * just for grouping on UI
 */
export interface LogicCategory extends Dictionary {
    Name: MULTILANG_TEXT;
}
export const InfoObjectParametersMeta_Code: CODE = "InfoObjectParameters";
export const InfoObjectParametersArrayName = "Parameters";
export interface Parameter extends Dictionary {
    ChildIndex: number /* int32 */;
    Name?: MULTILANG_TEXT;
    Description?: MULTILANG_TEXT;
    ValueType?: VALUE_TYPE;
    DefaultValue?: ANY_TYPE;
    IsDisabled: boolean;
    IsActive: boolean;
    Meta?: Meta;
    Category?: LogicCategory;
    ObjectParameters?: ObjectParameter[];
}
export interface Paraset extends Object {
    Name: MULTILANG_TEXT;
    IsDisabled: boolean;
    /**
     * IsMain     bool                 `json:"IsMain"` // единственный парасет в системе должен быть главным   // done: added is_main UPDATE: нешили не использовать а лучше испоьзовать CODE
     */
    Code?: CODE;
    /**
     * старые параметры
     */
    ChildParams: ParasetParam[];
}
/**
 * СТАРЫЕ ПАРАМЕТРЫ!
 */
export interface ParasetParam extends Object {
    ParentParaset_Id: OBJECT_ID;
    /**
     * CategoryId      *OBJECT_ID `json:"Category_Id"` // just for grouping on UI, nullable
     */
    Category_Code?: CODE; // just for grouping on UI, nullable
    ParamName: string; // parameter's code (used by developers) is here
    Description: MULTILANG_TEXT;
    DefaultValue?: string; // nullable
    ValueType: VALUE_TYPE;
    /**
     * Category      *ParasetParamCategory `gorm:"foreignKey:Category_Id;references:Id"`
     */
    Category?: LogicCategory;
    ParentParaset?: Paraset;
}
/**
 * Новые параметры - привязанные к объекту
 */
export interface ObjectParameter extends Object {
    Object_Id: OBJECT_ID;
    ParameterValue?: ANY_TYPE;
    Meta?: Meta;
    Parameter?: Parameter;
}

//////////
// source: info_partners.go

export const InfoObjectPartnersMeta_Code: CODE = "InfoObjectPartners";
export const InfoObjectPartnersArrayName = "Partners";
/**
 * Все возможные роли БП
 */
export interface PartnerRole extends Dictionary {
    Name: MULTILANG_TEXT;
}
/**
 * Все возможные (и необходимые) роли БП у Меты
 */
export interface Partner extends Object {
    Meta_Code: CODE;
    PartnerRole_Code: CODE;
    PartnerRole?: PartnerRole;
}
export interface ObjectPartner extends Object {
    Meta_Code: CODE; // Used as ObjectType, это НЕ FK
    Object_Id: OBJECT_ID; // ID of the parent model. Может быть и int64 и UUID!
    PartnerRole_Code: CODE;
    /**
     * Тут ссылка на документ Партнер (FK на cat_partners(id)) (с учетом PartnerRole_Code на его конкретную роль которая в нем обязана быть)
     * может быть NULL если еще не заполнено
     */
    Partner_Id?: OBJECT_ID;
}

//////////
// source: info_properties.go

export const InfoObjectPropertiesMeta_Code: CODE = "InfoObjectProperties";
export const InfoObjectPropertiesArrayName = "Properties";
export interface Property extends Dictionary {
    Meta_Code: CODE;
    Name: MULTILANG_TEXT;
    Description: MULTILANG_TEXT;
    LayoutArea?: string; // Вкладка/Секция например "Main/Header", "Main/Footer", "Main/Audit", "Main/Statuses", "Additional"
    ChildIndex?: number /* int */; // порядковый номер внутри LayoutArea
    ValueType: VALUE_TYPE; // can be "string", "bool", "int", ..., "ref:meta.field", "ref:meta.field,filter:field=value"
}
/**
 * ObjectProperty represents current object properties.
 */
export interface ObjectProperty extends Object {
    /**
     * Polymorphic fields (NB! But we dont use GORM's polymorphic associations here - it doesnt work the way we want! We emulate it using Hooks)
     */
    Property_Code?: CODE; // это FK по сути тут ссылка на парента - на проперти экземпляром которого мы являемся
    Meta_Code: CODE; // Used as ObjectType, это НЕ FK
    Object_Id: OBJECT_ID; // ID of the parent model. Может быть и int64 и UUID!
    PropertyValue?: ANY_TYPE; // nullable
    Property?: Property;
}

//////////
// source: info_scopes.go

export const InfoObjectScopesMeta_Code: CODE = "InfoObjectScopes";
export const InfoObjectScopesArrayName = "Scopes";
export interface Scope extends Dictionary {
    /**
     * Name Наименование
     */
    Name?: MULTILANG_TEXT;
    /**
     * ChildIndex Порядковый номер
     */
    ChildIndex?: number /* int32 */;
    /**
     * IsDisabled Отключено
     */
    IsDisabled?: boolean;
    /**
     * IsActive Активность
     */
    IsActive?: boolean;
    Parent_Code?: CODE;
    Parent?: Scope;
    ShowOnUi?: boolean;
    Description?: MULTILANG_TEXT;
    Icon?: string;
    Comment?: string;
}
export interface ObjectScope extends Object {
    Meta_Code?: CODE;
    Object_Id?: OBJECT_ID;
    Scope_Code?: CODE;
}

//////////
// source: info_statuses.go

export const InfoObjectStatusesMeta_Code: CODE = "InfoObjectStatuses";
export const InfoObjectStatusesArrayName = "Statuses";
export const InfoObjectStatusesTableName = "info_object_statuses";
/**
 * StatusGroup represents the info_status_groups table
 */
export interface StatusGroup extends Dictionary {
    Meta_Code: CODE;
    ChildIndex?: number /* int */;
    Name: MULTILANG_TEXT;
    Description: MULTILANG_TEXT;
    IsMain: boolean;
    IsVisibleInList: boolean;
    InitialStatusCode?: CODE; // Added later via ALTER TABLE
    IsActive?: boolean;
    /**
     * Meta          Meta     `gorm:"foreignKey:Meta_Code;references:Code"`
     */
    ChildStatuses: Status[]; // TODO: называтьли "Child"? тут и в других местах? Как удобнее для фронтенда? У Trip ведь есть Stops а не ChildStops!
    InitialStatus?: Status;
}
/**
 * Statuses represents the dict_statuses table
 */
export interface Status extends Dictionary {
    ParentStatusGroup_Code: CODE;
    ChildIndex?: number /* int */;
    Name: MULTILANG_TEXT;
    Description: MULTILANG_TEXT;
    Color?: COLOR;
    ParentStatusGroup?: StatusGroup;
}
/**
 * ObjectStatus represents current object statuses.
 */
export interface ObjectStatus extends Object {
    /**
     * Polymorphic fields (NB! But we dont use GORM's polymorphic associations here - it doesnt work the way we want! We emulate it using Hooks)
     */
    Meta_Code: CODE; // Used as ObjectType, это НЕ FK
    Object_Id: OBJECT_ID; // ID of the parent model. Может быть и int64 и UUID!
    StatusGroup_Code: CODE;
    Status_Code: CODE;
    Comment?: string;
    /**
     * Meta        Meta        `gorm:"foreignKey:Meta_Code;references:Code"`
     */
    StatusGroup?: StatusGroup;
    Status?: Status;
}

//////////
// source: info_tenants.go

export interface Tenant extends Dictionary {
    /**
     * Name Наименование
     */
    Name?: MULTILANG_TEXT;
    /**
     * ChildIndex Порядковый номер
     */
    ChildIndex?: number /* int32 */;
    Icon?: string;
    DbConnectionIv: string;
    DbConnection: string;
    /**
     * IsDisabled Отключено
     */
    IsDisabled?: boolean;
    /**
     * IsActive Активность
     */
    IsActive?: boolean;
}
export interface TenantUser extends Object {
    /**
     * ChildIndex Порядковый номер
     */
    ChildIndex?: number /* int32 */;
}

//////////
// source: info_users_roles.go

/**
 * //////////
 */
export interface Role extends Dictionary {
    Name?: MULTILANG_TEXT;
    IsDisabled?: boolean;
    Subroles?: RoleSubrole[];
    NavItems?: RoleNavItem[];
    Actions?: RoleAction[];
    Layouts?: RoleLayout[];
}
export interface RoleSubrole extends Object {
    ChildIndex?: number /* int */; // порядок ролей - влияет на порядок меню
    Role?: Role;
    Subrole?: Role;
}
export interface RoleNavItem extends Object {
    Role_Code: CODE; // меню внутри этой роли
    Parent_Id?: OBJECT_ID; // Pointer to allow null values for top-level items (те, у кого есть дети будут считаться папками)
    ChildIndex?: number /* int */; // порядок внутри родителя (если есть) или внутри вернхнего уровня
    Is_Disabled: boolean;
    IsHidden: boolean; // скрыть из меню но иметь в  роутах
    IsOnSpringboard: boolean; // показывать на спрингборде
    NavItem_Id?: OBJECT_ID; // ссылка на пункт меню (может быть как у папок так и у обычных элементов)
    Name: MULTILANG_TEXT; // переопределение названия пункта меню - для пункта меню не обязательно а для папки обязательно
    Icon: string; // переопределение Material-UI icon name
    /**
     * LayoutData LayoutData           `gorm:"column:layout_data;type:jsonb" json:"LayoutData"` // nullable переопределение дефолтного лайаута
     */
    Layout_Id?: OBJECT_ID; // ссылка на лайаут для пункта меню
    Role?: Role;
    NavItem?: NavItem;
    Parent?: RoleNavItem; // ссылка на родителя
    Layout?: Layout; // ссылка на лайаут для пункта меню
}
export interface RoleAction extends Object {
    Role_Code: CODE;
    Action_Id: OBJECT_ID; // однозначно определяет и мета-объект и действие которое мы тут включаем или выключаем
    IsEnabled: boolean; // включено или выключено действие для роли
    Role?: Role;
    Action?: Action;
    NavItem?: NavItem; // тут nav item для которого этот экшн применяется. это НЕ nav item из action (там это ссылка - переход по самому действию)
}
export interface User extends Object {
    User_Uuid?: USERID; // UUID из Supabase заполняется перед созданием юзера поэтому не может быть пустым хотя в БД пока оставим NULLABLE
    Name: MULTILANG_TEXT;
    LastName: MULTILANG_TEXT;
    FirstName: MULTILANG_TEXT;
    MiddleName: MULTILANG_TEXT;
    PhoneNumber?: string;
    Email?: string;
    BirthDate?: Date;
    AvatarFilePath?: string;
    AgreedWithTosAt?: Date;
    Paraset_Id?: OBJECT_ID; // Парасет для юзера - имеет приоритет над главным парасетом
    IsDisabled: boolean;
    ExpirationDateAt?: Date;
    RlsProperties: ANY_DATA; // RLS properties for user
    Telegram?: string;
    Language?: Dictionary;
    Paraset?: Paraset;
    Roles?: UserRole[]; // роли пользователя
    ApiKeys?: UserApiKey[]; // ключи доступа пользователя
}
export interface UserRole extends Object {
    ChildIndex?: number /* int */; // порядок ролей - влияет на порядок меню
    User?: User;
    Role?: Role;
}
export interface UserApiKey extends Object {
    ChildIndex?: number /* int */; // порядок ролей - влияет на порядок меню
    Name: MULTILANG_TEXT;
    TokenHash: string; // соленый хэш  API KEY
    ExpiresAt?: Date;
    IsActive: boolean;
    User?: User;
}
export interface UserCredential extends Object {
    ProviderCode: CODE;
    Identifier: string;
    PasswordHash: string;
    Metadata: ANY_DATA;
    IsActive: boolean;
    User?: User;
}
/**
 * RLS Policies
 */
export interface RolePolicy extends Object {
    Role_Code?: CODE; // роль для которой настраивается RLS
    Meta_Code: CODE; // мета для таблицы которой настраивается RLS
    Condition?: string; // условие для RLS
    IsReadOnly: boolean;
    /**
     * IsProhibited bool           `gorm:"column:is_prohibited;not null;default:false" json:"IsProhibited"` решили НЕ ДЕЛАТЬ
     */
    Description: MULTILANG_TEXT;
    UiAllowSelect: boolean;
    UiAllowView: boolean;
    UiAllowEdit: boolean;
    UiAllowCreate: boolean;
    UiAllowSave: boolean;
    UiAllowDelete: boolean;
    ApiAllowInfo: boolean;
    ApiAllowSelect: boolean;
    ApiAllowGet: boolean;
    ApiAllowSave: boolean;
    ApiAllowPreloadRef: boolean;
    ApiAllowSelectConditionQuery?: SavedQuery;
    ApiAllowGetConditionQuery?: SavedQuery;
    ApiAllowSaveConditionQuery?: SavedQuery;
}
export interface RoleLayout extends Object {
    Role_Code: CODE; // роль для которой настраивается RLS
    Info: string; // переопределение меты
    TablePage: string; // определение лайаута для табличной страницы
    DetailPage: string; // определение лайаута для детальной страницы
}

//////////
// source: layout_data.go

export interface AreaData {
    AreaIndex: number /* int */;
}
export type LayoutData = { [key: string]: AreaData | undefined};

//////////
// source: local_datetime.go

export type LOCAL_DATETIME = Date;

//////////
// source: saved_query.go

export interface SavedQuery extends Object {
    Code?: CODE;
    Key?: any /* types.KEY */;
    Name: MULTILANG_TEXT;
    Type_Code?: CODE;
    ChildIndex?: number /* int32 */;
    Meta_Code?: CODE;
    NavItem_Id?: OBJECT_ID;
    Filter?: string;
    FilterData?: ANY_TYPE;
    FilterSql?: string;
    IsPublic?: boolean;
    User_Id?: OBJECT_ID;
}
