import loadable from '@loadable/component';
import { App as AntdApp, Button, Flex, Progress } from 'antd';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { lazy, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { AliveScope } from 'react-activation';
import { I18nextProvider, initReactI18next, useTranslation } from 'react-i18next';
import posthog from 'posthog-js';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import {
    createGlobalState,
    useAsync,
    useBoolean,
    useEffectOnce,
    useFavicon,
    useMedia
} from 'react-use';
import { UniversalBoundary } from 'components';
import { Layout } from 'modules';
import { META } from 'modules/services/backend-api/generated_types';
import { useAuthUser } from 'modules/client/useAuthUser';
// import { KeepAlivedSmartDetailPage, KeepAlivedSmartTablePage } from 'smart/pages';
import { ILogMessagesModal, LogMessagesModal } from 'smart/components';
import { getFileUrlForDownload } from 'smart/utils';
import { parseEnvTheme } from 'utils/helpers/parseEnvTheme';
import { SkeletonLoader } from 'ui/Skeleton/Skeleton';
import { routeStore } from 'utils/store';
import { metaStore } from 'utils/store/MetaStore';
import { useStoreNavigate } from 'utils/store/utils/hooks/useStoreNavigate';
import { languagesStore } from 'utils/store/languages/languagesStore';

import { allRoutes as routes } from '../routing/routes';
import { Loader } from '../ui/Loader/Loader';
import { i18n } from '../utils/i18n/i18n';
import { DEFAULT_LANG } from './constants';

import { AppProvidersWrapper } from './contexts';
import '../scss/style.scss';
import { NotificationResponse } from '../modules/services/backend-api/generated_api';
import { AppLoader } from './components';

const LoginPage = lazy(() =>
    import('pages/LoginPage').then((module) => ({ default: module.LoginPage }))
);
const LoginPageSSO = lazy(() =>
    import('pages/LoginPageSSO').then((module) => ({ default: module.LoginPageSSO }))
);
const CallbackSSO = lazy(() =>
    import('pages/CallbackSSO').then((module) => ({ default: module.CallbackSSO }))
);
const SignupPage = lazy(() =>
    import('pages/SignupPage').then((module) => ({ default: module.SignupPage }))
);

const InvitePage = lazy(() =>
    import('pages/InvitePage').then((module) => ({ default: module.InvitePage }))
);

const PublicOffertPage = lazy(() =>
    import('pages/public-offert/PublicOffertPage').then((module) => ({
        default: module.PublicOffertPage
    }))
);
const VerifyPage = lazy(() =>
    import('pages/VerifyPage').then((module) => ({ default: module.VerifyPage }))
);
lazy(() =>
    import('pages/ChangePasswordPage').then((module) => ({ default: module.ChangePasswordPage }))
);
const KeepAlivedSmartDetailPage = lazy(() =>
    import('smart/pages/KeepAlivedSmartDetailPage').then((module) => ({
        default: module.KeepAlivedSmartDetailPage
    }))
);
lazy(() =>
    import('smart/pages/KeepAlivedSmartOverviewPage').then((module) => ({
        default: module.KeepAlivedSmartOverviewPage
    }))
);
const KeepAlivedSmartTablePage = lazy(() =>
    import('smart/pages/KeepAlivedSmartTablePage').then((module) => ({
        default: module.KeepAlivedSmartTablePage
    }))
);

const KeepAlivedTarifCalculatorPage = lazy(() =>
    import('pages/tariff-calculator/TariffCalculatorPage').then((module) => ({
        default: module.KeepAlivedTariffCalculatorPage
    }))
);

const KeepAlivedComparingPage = lazy(() =>
    import('pages/ComparingPage').then((module) => ({
        default: module.KeepAlivedComparingPage
    }))
);

const KeepAlivedMapPage = lazy(() =>
    import('pages/maps/MapPage').then((module) => ({
        default: module.KeepAlivedMapPage
    }))
);

lazy(() =>
    import('pages/tms-reports/margin-orders/MarginOrders_Page').then((module) => ({
        default: module.MarginOrders_Page
    }))
);

const BookingSlotsPage = lazy(() =>
    import('smart/pages/BookingSlotsPage').then((module) => ({
        default: module.BookingSlotsPage
    }))
);

const CalendarTimeline = lazy(() =>
    import('smart/modules').then((module) => ({ default: module.CalendarTimeline }))
);

const GanttDiagramPage = lazy(() =>
    import('smart/pages').then((module) => ({ default: module.GanttDiagramPage }))
);

// const Stepper = lazy(() =>
//     import('smart/components').then((module) => ({ default: module.Stepper }))
// );

const KeepAlivedModifiedSmartTimelinePage = lazy(() =>
    import('smart/pages/KeepAlivedModifiedSmartTimelinePage').then((module) => ({
        default: module.KeepAlivedModifiedSmartTimelinePage
    }))
);

lazy(() => import('pages/SupersetPage').then((module) => ({ default: module.SupersetPage })));
lazy(() => import('pages/reports/ReportPage').then((module) => ({ default: module.ReportPage })));

const INITIAL_TITLE = 'IT Vectura | Logistics IT software';

export const useLogsModal = createGlobalState<ILogMessagesModal & { open: boolean }>({
    open: false,
    requestId: '',
    logs: { log_messages: [] }
});

export const useLogos = createGlobalState<{
    favicon: string | null;
    small: string | null;
    medium: string | null;
    large: string | null;
}>({
    favicon: null,
    small: null,
    medium: null,
    large: null
});

export const App: React.FC = observer(() => {
    const lastRoute = toJS(routeStore.routes.at(-1));
    // console.log(lastRoute);
    // const sbAuthToken = window.localStorage.getItem(supabaseStorageKey);

    // console.log(toJS(metaStore.meta.get('all')));

    // metaStore.api.need
    const { t } = useTranslation();
    const { user, loading, setUser, provider } = useAuthUser();
    const navigate = useStoreNavigate();
    const { pathname, search, state } = useLocation();
    const [logsModalState] = useLogsModal();
    const [logos, setLogos] = useLogos();
    const isBigMobile = useMedia('(max-width: 480px)');

    // console.log(logos.favicon ?? '!!!!!!!!!!');

    useFavicon(logos.favicon ?? '');

    // const [sbAuthToken] = useLocalStorage<{ access_token: string }>('sb-studio-auth-token');
    // const [isMetaLoading, setIsMetaLoading] = useBoolean(true);
    const [isError, setIsError] = useBoolean(false);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [numNotifications, setNumNotifications] = useState(0);

    const [needRelogin, setNeedRelogin] = useState<boolean>(false);

    useEffect(() => {
        if (metaStore.api) {
            metaStore.api.reloginSubscribe(() => {
                setNeedRelogin(true);
            });
        }

        return () => metaStore.api.reloginUnSubscribe();
    }, [metaStore.api, pathname]);

    useEffectOnce(() => {
        document.documentElement.lang = i18n.language || DEFAULT_LANG;
        document.title = INITIAL_TITLE;

        routeStore.push({
            pathname,
            search: '',
            state:
                state || search
                    ? { ...state, filterString: search?.slice(1) || state?.filterString }
                    : null
        });

        navigate(
            {
                pathname,
                search: ''
            },
            {
                state:
                    state || search
                        ? { ...state, filterString: search?.slice(1) || state?.filterString }
                        : null
            }
        );

        // routeStore.push({
        //     pathname,
        //     search: '',
        //     state: { ...state, filterString: search || state?.filterString }
        // });

        (async () => {
            const isTolgeeDisabled = await metaStore.getParam({
                param_name: 'DISABLE_TOLGEE'
            });

            if (isTolgeeDisabled) {
                // disable tolgee
                console.log('[i18n] disabled');
                await i18n.use(initReactI18next).init({
                    resources: {
                        ru: {
                            translations: '{}'
                        },
                        kk: {
                            translations: '{}'
                        },
                        en: {
                            translations: '{}'
                        },
                        az: {
                            translations: '{}'
                        },
                        tr: {
                            translations: '{}'
                        },
                        tech: {
                            translations: JSON.stringify({})
                        }
                    }
                });
            }
        })();
    });

    const isConnected = metaStore.api?.channel?.isConnected;

    useEffect(() => {
        if (!isConnected || !user) {
            setNumNotifications(0);
            return () => {};
        }

        const subscribe = async () => {
            const unsubscribe = await metaStore.subscribeNotifications(
                (notification: NotificationResponse) => {
                    setNumNotifications(notification.num_unread);
                }
            );

            return () => {
                setNumNotifications(0);

                if (unsubscribe) {
                    unsubscribe();
                }
            };
        };

        const cleanup = subscribe();

        return () => {
            cleanup.then((unsubscribe) => {
                if (unsubscribe) {
                    unsubscribe();
                }
            });
        };
    }, [isConnected, user]);

    useEffect(() => {
        if (window.api) languagesStore.fetch();
    }, [window.api]); // убрал sbAuthToken из зависимостей

    const fectchAndSetLogos = useCallback(
        async (
            favicon?: string | null,
            small?: string | null,
            medium?: string | null,
            large?: string | null
        ) => {
            let logoFaviconUrl = favicon ?? null;
            let logoSmallUrl = small ?? null;
            let logoMediumUrl = medium ?? null;
            let logoLargeUrl = large ?? null;

            try {
                if (favicon) logoFaviconUrl = await getFileUrlForDownload('public', favicon);
                if (small) logoSmallUrl = await getFileUrlForDownload('public', small);
                if (medium) logoMediumUrl = await getFileUrlForDownload('public', medium);
                if (large) logoLargeUrl = await getFileUrlForDownload('public', large);

                setLogos({
                    favicon: logoFaviconUrl,
                    small: logoSmallUrl,
                    medium: logoMediumUrl,
                    large: logoLargeUrl
                });
            } catch (error) {
                console.error('error downloading logos:', error);
            }
        },
        []
    );

    const { loading: isMetaLoading } = useAsync(async () => {
        try {
            const envTheme = parseEnvTheme(window.env.APP_THEME);
            if (!user && envTheme) {
                await fectchAndSetLogos(
                    envTheme.favicon,
                    envTheme.logoSmall,
                    envTheme.logoMedium,
                    envTheme.logoLarge
                );
            }

            if (user) {
                // identfy with email, of not defined then id
                posthog.identify(user.email || user.id);

                await metaStore.getRoutes();
                setLoadingProgress(25);
                await Promise.all([
                    metaStore.getMenu(),
                    metaStore.makeSelect({ meta: 'InfoMeta' }),
                    metaStore.getInfo('DocOrders'),
                    metaStore.getInfo('DocTrips'),
                    metaStore.getInfo('InfoObjectProperties'),
                    metaStore.getInfo('InfoRequests'),
                    metaStore.getParam({
                        param_name: 'SHOW_LANGUAGE_SWITCHER',
                        default_value: false
                    })
                ]);
                setLoadingProgress(65);

                const theme = await metaStore.getParam({
                    param_name: 'APP_THEME',
                    default_value: null
                });
                setLoadingProgress(90);

                if (theme || envTheme) {
                    await fectchAndSetLogos(
                        theme?.favicon ?? envTheme?.favicon,
                        theme?.logoSmall ?? envTheme?.logoSmall,
                        theme?.logoMedium ?? envTheme?.logoMedium,
                        theme?.logoLarge ?? envTheme?.logoLarge
                    );
                }
            }
            setLoadingProgress(100);
        } catch (error) {
            console.error(error);
            setIsError(true);
        } finally {
            setLoadingProgress(0);
        }
    }, [user]);

    if (loading || isMetaLoading)
        return (
            <AppProvidersWrapper i18n={i18n}>
                <AppLoader percent={loadingProgress} />
            </AppProvidersWrapper>
        );

    if (!loading && !user) {
        return (
            <AppProvidersWrapper i18n={i18n}>
                <I18nextProvider i18n={i18n}>
                    <AntdApp>
                        <Suspense fallback={<Loader />}>
                            <Routes>
                                <Route path="/verify" element={<VerifyPage />} />
                                <Route
                                    path="/login"
                                    element={<LoginPage setUser={setUser} provider={provider} />}
                                />
                                <Route path="/signup" element={<SignupPage setUser={setUser} />} />
                                <Route path="/invited" element={<InvitePage setUser={setUser} />} />
                                <Route
                                    path="/offer"
                                    element={
                                        <PublicOffertPage
                                            open={pathname.includes('/offer')}
                                            setOpen={() => navigate(-1)}
                                        />
                                    }
                                />
                                <Route path="/login-sso" element={<LoginPageSSO />} />
                                <Route
                                    path="/auth/callback"
                                    element={<CallbackSSO setUser={setUser} />}
                                />
                                <Route path="*" element={<Navigate to={`/login`} />} />
                            </Routes>
                        </Suspense>
                    </AntdApp>
                </I18nextProvider>
            </AppProvidersWrapper>
        );
    }
    if (needRelogin) {
        return (
            <Flex justify="center" align="center" style={{ width: '100vw', height: '100vh' }}>
                <UniversalBoundary
                    status="403"
                    title={`${t('unauthorized_title')}...`}
                    subTitle={`${t('unauthorized_subtitle')}`}
                    enableTryAgain={false}
                    enableGoBackOrGoHome={false}
                    enableLogin={true}
                    onLoginButtonPress={() => {
                        localStorage.clear();
                        sessionStorage.clear();
                        setNeedRelogin(false);
                        // routeStore.remove({ pathname, search, state });
                        setUser(null);
                    }}
                >
                    {/* <Button>Войти</Button> */}
                </UniversalBoundary>
            </Flex>
        );
    }

    if (isError)
        // тут ошибка если
        return (
            <Flex justify="center" align="center" style={{ width: '100vw', height: '100vh' }}>
                <UniversalBoundary
                    status="error"
                    title={`${t('something_goes_wrong')}...`}
                    subTitle={`${t('please_try_again')}`}
                    enableTryAgain
                    enableGoBackOrGoHome={false}
                    onTryAgain={() => window.location.reload()}
                    enableLogin={true}
                    onLoginButtonPress={() => {
                        localStorage.clear();
                        sessionStorage.clear();
                        setNeedRelogin(false);
                        // routeStore.remove({ pathname, search, state });
                        setUser(null);
                    }}
                ></UniversalBoundary>
            </Flex>
        );

    const metaRoutes = [...(toJS(metaStore.meta.get('all')?.routes) || [])];
    // console.log(metaRoutes.filter((r) => r.path?.includes('gantt')));
    // console.log(
    //     '[App] meta routes: ',
    //     metaRoutes.filter((l) => !!l.layout)
    // );
    const L = isBigMobile ? Layout.MobileLayout : Layout.DefaultLayout;

    return (
        // <AppProvidersWrapper i18n={i18n} user={user}>
        <AppProvidersWrapper i18n={i18n}>
            <AliveScope>
                <AntdApp>
                    <L user={user} setUser={setUser} numNotifications={numNotifications}>
                        <Suspense fallback={<Loader />}>
                            <Routes>
                                <Route path="/verify" element={<Navigate to="/" />} />
                                <Route
                                    path="/login"
                                    element={
                                        <Navigate
                                            // to="/"
                                            to={{
                                                pathname: lastRoute?.pathname,
                                                search: lastRoute?.search
                                            }}
                                            state={lastRoute?.state}
                                        />
                                    }
                                />
                                <Route
                                    path="/signup"
                                    element={
                                        <Navigate
                                            // to="/"
                                            to={{
                                                pathname: lastRoute?.pathname,
                                                search: lastRoute?.search
                                            }}
                                            state={lastRoute?.state}
                                        />
                                    }
                                />
                                <Route
                                    path="/offer"
                                    element={
                                        <PublicOffertPage
                                            open={pathname.includes('/offer')}
                                            setOpen={() => navigate(-1)}
                                        />
                                    }
                                />

                                <Route
                                    path="/other/:meta"
                                    element={<KeepAlivedSmartTablePage meta={''} />}
                                />

                                <Route
                                    path="/other/:meta/:id"
                                    element={<KeepAlivedSmartDetailPage meta={''} />}
                                />

                                <Route path={'/calendar-timeline'} element={<CalendarTimeline />} />
                                {/* <Route path={'/stepper'} element={<Stepper />} /> */}

                                {routes.map((route) => (
                                    <Route
                                        key={route.path}
                                        path={route.path}
                                        element={
                                            // <RequirePermissions path={route.path}>
                                            <route.component />
                                            // </RequirePermissions>
                                        }
                                    />
                                ))}

                                {metaRoutes
                                    .filter(
                                        (metaRoute) =>
                                            !routes.find((route) => route.path === metaRoute.path)
                                    )
                                    .map((route) => ({
                                        ...route,
                                        path: route.path.split('?')[0]
                                    }))
                                    .map((metaRoute) => {
                                        // console.log('metaRoute:', metaRoute);
                                        if (
                                            metaRoute.component?.includes(
                                                'KeepAlivedModifiedSmartTimelinePage'
                                            )
                                        ) {
                                            return (
                                                <Route
                                                    key={metaRoute.path}
                                                    path={metaRoute.path}
                                                    element={
                                                        <KeepAlivedModifiedSmartTimelinePage />
                                                    }
                                                />
                                            );
                                        }
                                        if (metaRoute.component?.includes('BookingSlotsPage')) {
                                            return (
                                                <Route
                                                    key={metaRoute.path}
                                                    path={metaRoute.path}
                                                    element={<BookingSlotsPage />}
                                                />
                                            );
                                        }
                                        if (
                                            metaRoute.component?.includes(
                                                'KeepAlivedSmartTablePage'
                                            )
                                        ) {
                                            return (
                                                <Route
                                                    key={metaRoute.path}
                                                    path={metaRoute.path}
                                                    element={
                                                        <KeepAlivedSmartTablePage
                                                            meta={metaRoute.meta || ''}
                                                        />
                                                    }
                                                />
                                            );
                                        }
                                        if (
                                            metaRoute.component?.includes(
                                                'KeepAlivedSmartDetailPage'
                                            )
                                        ) {
                                            return (
                                                <Route
                                                    key={metaRoute.path}
                                                    path={metaRoute.path}
                                                    element={
                                                        <KeepAlivedSmartDetailPage
                                                            meta={metaRoute.meta || ''}
                                                        />
                                                    }
                                                />
                                            );
                                        }
                                        if (
                                            metaRoute.component?.includes(
                                                'KeepAlivedTarifCalculatorPage'
                                            )
                                        ) {
                                            return (
                                                <Route
                                                    key={metaRoute.path}
                                                    path={metaRoute.path}
                                                    element={<KeepAlivedTarifCalculatorPage />}
                                                />
                                            );
                                        }

                                        if (
                                            metaRoute.component?.includes('KeepAlivedComparingPage')
                                        ) {
                                            return (
                                                <Route
                                                    key={metaRoute.path}
                                                    path={metaRoute.path}
                                                    element={<KeepAlivedComparingPage />}
                                                />
                                            );
                                        }

                                        if (metaRoute.component?.includes('Gantt')) {
                                            return (
                                                <Route
                                                    key={metaRoute.path}
                                                    path={metaRoute.path}
                                                    element={
                                                        <GanttDiagramPage
                                                            meta={metaRoute.meta || ''}
                                                        />
                                                    }
                                                />
                                            );
                                        }
                                        if (metaRoute.component?.includes('MapPage')) {
                                            return (
                                                <Route
                                                    key={metaRoute.path}
                                                    path={metaRoute.path}
                                                    element={<KeepAlivedMapPage />}
                                                />
                                            );
                                        }

                                        if (metaRoute.component) {
                                            const fallback = <SkeletonLoader />;
                                            const componentPath =
                                                metaRoute.component[0] !== '/'
                                                    ? `../${metaRoute.component}`
                                                    : `..${metaRoute.component}`;

                                            const componentName = metaRoute.component
                                                .split('/')
                                                .at(-1);

                                            const resolveComponent = (components: any) => {
                                                if (componentName) {
                                                    return components[componentName?.trim()];
                                                }
                                                return fallback;
                                            };

                                            const cacheKey = () => metaRoute.component;

                                            const prodImportCb = () =>
                                                import(
                                                    /* @vite-ignore */ `/assets/${componentName}.js`
                                                );

                                            const devImportCb = () =>
                                                import(/* @vite-ignore */ `${componentPath}`);

                                            const DynamicComponent = loadable<{ meta: META }>(
                                                import.meta.env.MODE === 'development'
                                                    ? devImportCb
                                                    : prodImportCb,
                                                {
                                                    fallback,
                                                    resolveComponent,
                                                    cacheKey
                                                }
                                            );

                                            return (
                                                <Route
                                                    key={metaRoute.path}
                                                    path={metaRoute.path}
                                                    element={
                                                        <DynamicComponent
                                                            meta={metaRoute.meta || ''}
                                                        />
                                                    }
                                                />
                                            );
                                        }

                                        // ### DEFAULT ###
                                        const potencialDetailPath = `${metaRoute.path}/:id`;
                                        const hasDetailInMeta = !!metaRoutes.find((route) =>
                                            route.path.includes(potencialDetailPath)
                                        );

                                        if (!hasDetailInMeta) {
                                            return (
                                                <React.Fragment key="hasDetailInMeta">
                                                    <Route
                                                        key={metaRoute.path}
                                                        path={metaRoute.path}
                                                        element={
                                                            <KeepAlivedSmartTablePage
                                                                meta={metaRoute.meta || ''}
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        key={potencialDetailPath}
                                                        path={potencialDetailPath}
                                                        element={
                                                            <KeepAlivedSmartDetailPage
                                                                meta={metaRoute.meta || ''}
                                                            />
                                                        }
                                                    />
                                                </React.Fragment>
                                            );
                                        }

                                        return (
                                            <Route
                                                key={metaRoute.path}
                                                path={metaRoute.path}
                                                element={
                                                    <KeepAlivedSmartTablePage
                                                        meta={metaRoute.meta || ''}
                                                    />
                                                }
                                            />
                                        );
                                    })}
                            </Routes>
                        </Suspense>

                        <LogMessagesModal
                            open={logsModalState.open}
                            requestId={logsModalState.requestId}
                            logs={logsModalState.logs}
                            onCancel={logsModalState.onCancel}
                            hardDuration={logsModalState.hardDuration}
                        />
                    </L>
                </AntdApp>
            </AliveScope>
        </AppProvidersWrapper>
    );
});
