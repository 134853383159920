import { FilterOutlined } from '@ant-design/icons';
import { isDefined } from 'is-lite/exports';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from 'antd';
import { useMedia } from 'react-use';
import { SmartTableFilterMenuModal } from './SmartTableFilterMenuModal';
import { FilterField } from './types';

export interface TableFilterMenuToolbarButtonProps {
    filters: FilterField[];
    setFilters: Dispatch<SetStateAction<FilterField[]>>;
    meta: string;
    filtersFromLayout: FilterField[];
    buttonType?: 'default' | 'link' | 'text' | 'primary' | 'dashed' | undefined;
}

export const SmartTableFilterMenuToolbarButton = ({
    filters,
    setFilters,
    meta,
    filtersFromLayout,
    buttonType = 'default'
}: TableFilterMenuToolbarButtonProps) => {
    const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
    const isTouchScreen = useMedia('(hover: none)');

    const {
        t,
        i18n: { language }
    } = useTranslation();

    const tooltipText = useMemo(() => {
        if (filters.length === 0) {
            return t('filtering');
        }

        let text = `${t('filter_by')}: `;

        for (let i = 0; i < filters.length; i++) {
            const filter = filters[i];
            const field = filter.field;
            const fieldTitle = field.Name?.[language] ?? t(field.ColumnName);
            const splitter = i === filters.length - 1 ? '' : ', ';

            text = `${text}${fieldTitle}${splitter}`;
        }

        return text;
    }, [t, filters, language]);

    const activeFiltersLength = useMemo(() => {
        return filters.filter(
            (f) =>
                isDefined(f.values?.[0]?.value) ||
                f.values?.[0]?.operator === 'today' ||
                f.values?.[0]?.operator === 'tomorrow' ||
                f.values?.[0]?.operator?.startsWith('current') ||
                f.values?.[0]?.operator === 'empty' ||
                f.values?.[0]?.operator === 'notEmpty'
        ).length;
    }, [filters]);

    // console.log('filters', filters);

    return (
        <>
            <SmartTableFilterMenuModal
                open={showFilterModal}
                setOpen={setShowFilterModal}
                meta={meta}
                filters={filters}
                setFilters={setFilters}
                filtersFromLayout={filtersFromLayout}
            />

            <Tooltip title={isTouchScreen ? undefined : tooltipText} placement="bottomLeft">
                <Button
                    type={buttonType}
                    onClick={() => {
                        setShowFilterModal(true);
                    }}
                    icon={<FilterOutlined />}
                >
                    {activeFiltersLength || ''}
                </Button>
            </Tooltip>
        </>
    );
};
